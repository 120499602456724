import $e9N8g$cheerio from "cheerio";

var $13b04019e035f7c9$exports = {};

// default options
const $13b04019e035f7c9$var$defaultOptions = {
    // remove all tags
    stripTags: false,
    // postfix of the string
    ellipsis: "...",
    // decode html entities
    decodeEntities: false,
    // whether truncate by words
    byWords: false,
    // // truncate by words, set to true keep words
    // // set to number then truncate by word count
    // length: 0
    excludes: "",
    reserveLastWord: false,
    trimTheOnlyWord: false,
    keepWhitespaces: false // even if set true, continuous whitespace will count as one
};
const $13b04019e035f7c9$var$astralRange = /\ud83c[\udffb-\udfff](?=\ud83c[\udffb-\udfff])|(?:[^\ud800-\udfff][\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]?|[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\ud800-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?(?:\u200d(?:[^\ud800-\udfff]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?)*/g;
// helper method
const $13b04019e035f7c9$var$helper = {
    setup (length, options) {
        switch(typeof length){
            case "object":
                options = length;
                break;
            case "number":
                if (typeof options === "object") options.length = length;
                else options = {
                    length: length
                };
        }
        const fullOptions = this.extend(options, $13b04019e035f7c9$var$defaultOptions);
        // if (typeof fullOptions.length !== 'number') throw new TypeError('truncate-html: options.length should be a number')
        if (fullOptions.excludes) {
            if (!Array.isArray(fullOptions.excludes)) fullOptions.excludes = [
                fullOptions.excludes
            ];
            fullOptions.excludes = fullOptions.excludes.join(",");
        }
        this.options = fullOptions;
        this.limit = fullOptions.length;
        this.ellipsis = fullOptions.ellipsis;
        this.keepWhitespaces = fullOptions.keepWhitespaces;
        this.reserveLastWord = fullOptions.reserveLastWord;
        this.trimTheOnlyWord = fullOptions.trimTheOnlyWord;
    },
    // extend obj with dft
    extend (obj, dft) {
        if (obj == null) obj = {};
        for(const k in dft){
            const v = dft[k];
            if (obj[k] != null) continue;
            obj[k] = v;
        }
        return obj;
    },
    // test a char whether a whitespace char
    isBlank (char) {
        return char === " " || char === "\f" || char === "\n" || char === "\r" || char === "	" || char === "\v" || char === "\xa0" || char === "\u2028" || char === "\u2029";
    },
    /**
   * truncate text
   * @param  {String}  text        text to truncate
   * @param  {Boolean} isLastNode  is last dom node, help to decide whether add ellipsis
   * @return {String}
   */ truncate (text, isLastNode) {
        if (!this.keepWhitespaces) text = text.replace(/\s+/g, " ");
        const byWords = this.options.byWords;
        const match = text.match($13b04019e035f7c9$var$astralRange);
        const astralSafeCharacterArray = match === null ? [] : match;
        const strLen = match === null ? 0 : astralSafeCharacterArray.length;
        let idx = 0;
        let count = 0;
        let prevIsBlank = byWords;
        let curIsBlank = false;
        while(idx < strLen){
            curIsBlank = this.isBlank(astralSafeCharacterArray[idx++]);
            // keep same then continue
            if (byWords && prevIsBlank === curIsBlank) continue;
            if (count === this.limit) {
                // reserve trailing whitespace, only when prev is blank too
                if (prevIsBlank && curIsBlank) {
                    prevIsBlank = curIsBlank;
                    continue;
                }
                // fix idx because current char belong to next words which exceed the limit
                --idx;
                break;
            }
            if (byWords) curIsBlank || ++count;
            else curIsBlank && prevIsBlank || ++count;
            prevIsBlank = curIsBlank;
        }
        this.limit -= count;
        if (this.limit) return text;
        else {
            let str;
            if (byWords) str = text.substr(0, idx);
            else str = this.substr(astralSafeCharacterArray, idx);
            if (str === text) // if is lat node, no need of ellipsis, or add it
            return isLastNode ? text : text + this.ellipsis;
            else return str + this.ellipsis;
        }
    },
    // deal with cut string in the middle of a word
    substr (astralSafeCharacterArray, len) {
        // var boundary, cutted, result
        const cutted = astralSafeCharacterArray.slice(0, len).join("");
        if (!this.reserveLastWord || astralSafeCharacterArray.length === len) return cutted;
        const boundary = astralSafeCharacterArray.slice(len - 1, len + 1).join("");
        // if truncate at word boundary, just return
        if (/\W/.test(boundary)) return cutted;
        if (typeof this.reserveLastWord === "number" && this.reserveLastWord < 0) {
            const result = cutted.replace(/\w+$/, "");
            // if the cutted is not the first and the only word
            //   then return result, or return the whole word
            if (!(result.length === 0 && cutted.length === this.options.length)) return result;
            if (this.trimTheOnlyWord) return cutted;
        }
        // set max exceeded to 10 if this.reserveLastWord is true or < 0
        const maxExceeded = this.reserveLastWord !== true && this.reserveLastWord > 0 ? this.reserveLastWord : 10;
        const mtc = astralSafeCharacterArray.slice(len).join("").match(/(\w+)/);
        const exceeded = mtc ? mtc[1] : "";
        return cutted + exceeded.substr(0, maxExceeded);
    }
};
/** return true if elem is CheerioStatic */ function $13b04019e035f7c9$var$isCheerioInstance(elem) {
    return elem && elem.contains && elem.html && elem.parseHTML && true;
}
/**
 * truncate html
 * @method truncate(html, [length], [options])
 * @param  {String}         html    html string to truncate
 * @param  {Object|number}  length how many letters(words if `byWords` is true) you want reserve
 * @param  {Object|null}    options
 * @return {String}
 */ const $13b04019e035f7c9$var$truncate = function(html, length, options) {
    $13b04019e035f7c9$var$helper.setup(length, options);
    if (!html || isNaN($13b04019e035f7c9$var$helper.limit) || $13b04019e035f7c9$var$helper.limit <= 0 || $13b04019e035f7c9$var$helper.limit === Infinity) return html;
    // if (helper.limit)
    let $;
    // support provied cheerio
    if ($13b04019e035f7c9$var$isCheerioInstance(html)) $ = html;
    else // Add a wrapper for text node without tag like:
    //   <p>Lorem ipsum <p>dolor sit => <div><p>Lorem ipsum <p>dolor sit</div>
    $ = (0, $e9N8g$cheerio).load(`${html}`, {
        decodeEntities: $13b04019e035f7c9$var$helper.options.decodeEntities
    }, false);
    const $html = $.root();
    // remove excludes elements
    $13b04019e035f7c9$var$helper.options.excludes && $html.find($13b04019e035f7c9$var$helper.options.excludes).remove();
    // strip tags and get pure text
    if ($13b04019e035f7c9$var$helper.options.stripTags) return $13b04019e035f7c9$var$helper.truncate($html.text());
    const travelChildren = function($ele, isParentLastNode = true) {
        const contents = $ele.contents();
        const lastIdx = contents.length - 1;
        return contents.each(function(idx) {
            switch(this.type){
                case "text":
                    if (!$13b04019e035f7c9$var$helper.limit) {
                        $(this).remove();
                        return;
                    }
                    this.data = $13b04019e035f7c9$var$helper.truncate($(this).text(), isParentLastNode && idx === lastIdx);
                    break;
                case "tag":
                    if (!$13b04019e035f7c9$var$helper.limit) $(this).remove();
                    else return travelChildren($(this), isParentLastNode && idx === lastIdx);
                    break;
                default:
                    // for comments
                    return $(this).remove();
            }
        });
    };
    travelChildren($html);
    return $html.html();
};
$13b04019e035f7c9$var$truncate.setup = (options = {})=>{
    return Object.assign($13b04019e035f7c9$var$defaultOptions, options);
};
// fix parcel exporting issue
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
$13b04019e035f7c9$exports = $13b04019e035f7c9$var$truncate;


export {$13b04019e035f7c9$exports as default};
